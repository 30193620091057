import React from 'react'
const Code = ({ children }) => {
  return (
    <code
      style={{
        // background: 'var(--clr-grey-10)',
        // color: 'var(--clr-grey-5)',
        marginLeft: `3px`,
        marginRight: `3px`,
        fontWeight: `400`,
        border: `none`,
        content: `none !important`,
        background: `#efd5ae`,
        // borderRadius: 'var(--radius)',
      }}
    >
      {children}
    </code>
  )
}

export default Code
