import React from 'react'
import { FiInfo } from 'react-icons/fi'
// import { GiBookmarklet } from 'react-icons/gi'
import { TiWarningOutline } from 'react-icons/ti'
// import { FaQuoteLeft } from 'react-icons/fa'
import styled from 'styled-components'

const Blockquote = ({ children, display }) => {
  if (display === 'warning')
    return (
      <Wrapper>
        <div className="container warning">
          <TiWarningOutline className="icon" />
          {children}
        </div>
      </Wrapper>
    )
  if (display === 'info')
    return (
      <Wrapper>
        <div className="container info">
          <FiInfo className="icon" />
          {children}
        </div>
      </Wrapper>
    )
  if (display === 'default') {
    return (
      <Wrapper>
        <div className="container default">
          <span className="container-content-indicator">
            {/* <GiBookmarklet
              style={{
                color: `rgb(56, 211, 236)`,
                fontSize: `30px`,
              }}
            /> */}
          </span>
          {children}
        </div>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <div className="quote" style={{}}>
          {/* <FaQuoteLeft className="quote-icon" /> */}
          {children}
        </div>
      </Wrapper>
    )
  }
}
const Wrapper = styled.blockquote`
  .container {
    font-family: var(--ff-primary);
    font-size: 0.9em;
    padding: 10px 40px 40px;
    position: relative;
    text-align: justify;
  }
  .container-content-indicator {
    /* font-size: 25px;
    left: 38px; */
    top: 5px;
    left: 5px;
    font-size: 26px;
    position: absolute;
    width: 50px;
  }
  @media (min-width: 1170px) {
    .container {
      /* margin-left: -2rem;
      margin-right: -2rem; */
    }
  }
  .icon {
    position: absolute;
    top: 0;
    left: -3px;
    background: var(--clr-white);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid var(--clr-white);
  }
  .info {
    background: var(--clr-primary-10);
    color: var(--clr-primary-1);
    border-color: var(--clr-primary-5);
    .icon {
      color: var(--clr-primary-5);
    }
  }
  .warning {
    background: #fffaeb;
    color: #513c06;
    border-color: #f7d070;
    .icon {
      color: #f7d070;
    }
  }
  .quote {
    color: rgb(132, 146, 166);
    padding-left: 20px;
    padding-right: 80px;
    margin: 0;
    /* border-left: #7F00FF; */
    /* border-left: 4px solid var(--clr-blue); */
    /* border-radius: 3px;
    border-left: 5px solid rgb(56, 211, 236);
    background: rgb(239, 242, 247);
    font-family: Roboto, sans-serif; */

    @media (min-width: 576px) {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      /* align-items: center; */
    }

    color: var(--clr-grey-5);
    line-height: 1.8;
    word-spacing: 0.5px;
    font-size: 1.2rem;
    margin: 2rem 0;

    .quote-icon {
      font-size: 6rem;
      color: var(--clr-primary-5);
    }
  }
`
export default Blockquote
