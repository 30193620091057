import React from 'react'

const myH2 = ({ children, title, id }) => {
  // if (title) {
  //   return (
  //     <HeadingTwo>
  //       <h2>{children}</h2>
  //       <div className="underline"></div>
  //     </HeadingTwo>
  //   )
  // }
  return (
    <h2
      id={id}
      style={{
        margin: '2rem 0',
        // color: 'var(--clr-blue)',
        // color: '#7F00FF',
        color: 'var(--clr-black-2)',
      }}
    >
      {children}
    </h2>
  )
}

const myH3 = ({ id, children }) => {
  return (
    <>
      <h3
        style={{
          fontSize: `1.6rem`,
          textTransform: `uppercase`,
        }}
        id={id}
      >
        {children}
      </h3>
      <div
        style={{
          marginBottom: `2rem`,
          width: `5rem`,
          height: `3px`,
          // background: `var(--clr-blue)`,
          // background: '#7F00FF',
          background: '#000000cc',
        }}
      />
    </>
  )
}

export { myH2, myH3 }
